import axios from "axios";
import { useState } from "react";

import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";

import { useDispatch, useSelector } from "react-redux";
import { setLoginIsOpen } from "../../../store/loginSlice";

export const MyTicketCard = ({ data, ticketId, orderNumber, performer }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state) => state.userState.user);
  const token = useSelector((state) => state.userState.token);
  const language = useSelector((state) => state.userState.language);

  if (userId === "" && token === "") {
    dispatch(setLoginIsOpen(true));
  }

  const downloadTicket = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/tickets/download/${orderNumber}/${ticketId}`,
        {
          userId,
          token,
          language,
        },
        {
          responseType: "blob", // Expecting a blob response
        }
      );

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${performer}-${ticketId + 1}.pdf`); // Specify the file name for PDF

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success(
        language === "en" ? "Ticket downloaded successfully!" : "Uspješno preuzeta ulaznica!",
        toastSetup("top-center", 5000)
      );
    } catch (error) {
      if (error.response) {
        const blob = error.response.data;
        const reader = new FileReader();

        reader.onload = () => {
          const jsonResponse = JSON.parse(reader.result);
          toast.error(
            language === "en"
              ? `Error: ${jsonResponse.message} Please report the issue to info@event.ba`
              : `Greška: ${jsonResponse.message} Prijavite problem na info@event.ba`,
            toastSetup("top-right", 6000)
          );
        };

        reader.readAsText(blob); // Read the blob as text to parse as JSON
      } else {
        // Handle other errors (network, etc.)
        toast.error(
          language === "en"
            ? "Network error: Please try again later."
            : "Greška u mreži: Pokušajte ponovo kasnije.",
          toastSetup("top-right", 6000)
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ticket-card">
      <div>
        <div className="ticket-parts">
          <span>Vlasnik:</span>
          <p>
            {data.name} {data.lname}
          </p>
        </div>
        <div className="ticket-parts">
          <span>Email:</span>
          <p>{data.email}</p>
        </div>
      </div>
      <div>
        <div className="ticket-parts">
          <span>Tip:</span>
          <p>{data.ticketName}</p>
        </div>
        <div className="ticket-parts">
          <span>Zona:</span>
          <p>{data.category}</p>
        </div>
      </div>

      <div
        className="ticket-parts"
        style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "8px" }}
      >
        <p style={{ marginBottom: "0px" }}>
          {data.price} <small>BAM</small>
        </p>
        <button disabled={loading} onClick={downloadTicket} style={{ margin: "0px auto" }}>
          Preuzmi PDF
        </button>
      </div>
    </div>
  );
};

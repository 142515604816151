import React from "react";

import { CheckCheck, X } from "lucide-react";

const OrderDisplay = ({ paymentReq, language }) => {
  const displayText = {
    status: language === "hr" ? "Status" : "Status",
    ticketsReserved: language === "hr" ? "Rezervirane ulaznice" : "Reserved tickets",
    ticketsDelivered: language === "hr" ? "Dostavljene ulaznice" : "Delivered tickets",
    totalPrice: language === "hr" ? "Ukupna cijena ulaznica" : "Total ticket price",
    ticketDownloadP1:
      language === "hr"
        ? `Ulaznice možete direktno preuzeti na `
        : "You can download your tickets directly from your profile under My tickets.",
    ticketDownoadP2: language === "hr" ? `pod rubrikom "Moje ulaznice"` : "under My tickets.",
  };

  const statusText = {
    pending: language === "hr" ? "U tijeku" : "Pending",
    approved: language === "hr" ? "Odobreno" : "Approved",
    declined: language === "hr" ? "Odbijeno" : "Declined",
  };

  return (
    <div
      style={{
        listStyle: "none",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        alignItems: "flex-start",
        margin: "15px auto",
        maxWidth: "300px",
        fontSize: "1.2rem",
      }}
    >
      <span>
        {displayText?.status}:{" "}
        <span
          style={{
            color:
              paymentReq?.status === "approved"
                ? "green"
                : paymentReq?.status === "pending"
                ? "yellow"
                : "red",
          }}
        >
          {statusText[paymentReq?.status]}
        </span>
      </span>
      <span>
        {displayText?.ticketsReserved}:{" "}
        {paymentReq?.concertUpdated ? <CheckCheck style={{ color: "green" }} /> : <X />}
      </span>
      <span>
        {displayText?.ticketsDelivered}:{" "}
        {paymentReq?.tickets_delivered ? <CheckCheck style={{ color: "green" }} /> : <X />}
      </span>
      <span>
        {displayText?.totalPrice}: {paymentReq?.ticketGenData?.totalAmount} BAM
      </span>
      <br />
      <span style={{ textAlign: "start" }}>
        {displayText?.ticketDownloadP1}{" "}
        <a style={{ color: "blue", fontWeight: "bold", margin: "0px auto" }} href="/profile">
          profilu
        </a>{" "}
        {displayText?.ticketDownoadP2}.
      </span>
    </div>
  );
};

export default OrderDisplay;

import React, { useEffect, useState } from "react";
import axios from "axios";

import { toast } from "react-toastify";
import { toastSetup } from "../../functions/toastSetup";

import { useDispatch, useSelector } from "react-redux";
import { setLoginIsOpen } from "../../store/loginSlice";
import OrderDisplay from "./OrderDisplay";

const OrderInfo = ({ orderNumber, successURL }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.userState.user);
  const token = useSelector((state) => state.userState.token);
  const language = useSelector((state) => state.userState.language);
  const [paymentReq, setPaymentReq] = useState(null);
  const [error, setError] = useState(null);

  if (userId === "" && token === "") {
    dispatch(setLoginIsOpen(true));
  }

  useEffect(() => {
    const getOrderInfo = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/payment/verify_order/${orderNumber}`,
          {
            token,
            userId,
            successURL,
            language,
          }
        );
        setPaymentReq(response.data);

        if (response.status === 201)
          toast.success(
            language === "en" ? "Order successfully confirmed" : "Narudžba uspješno potvrđena",
            toastSetup("top-right", 3000)
          );
        else if (response.status === 200)
          toast.info(
            language === "en"
              ? "Order has been confirmed automatically!"
              : "Narudžba je bila potvrđena automatski!",
            toastSetup("top-right", 3000)
          );
      } catch (error) {
        setError(error?.response?.data?.message);
        if (error.response?.status === 401) {
          dispatch(setLoginIsOpen(true));
        }
        toast.error(
          language === "en"
            ? `Error: ${error?.response?.data?.message} Please report the issue to info@event.ba`
            : `Greška: ${error?.response?.data?.message} Prijavite problem na info@event.ba`,
          toastSetup("top-right", 6000)
        );
      }
    };

    if (userId && token) {
      getOrderInfo();
    }
  }, [orderNumber, userId]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h4>
          {language === "en" ? `Order: ` : `Narudžba: `}
          {orderNumber}
        </h4>
      </div>
      {paymentReq ? (
        <OrderDisplay paymentReq={paymentReq} language={language} />
      ) : (
        <h5 style={{ color: "red" }}>{error}</h5>
      )}
    </>
  );
};

export default OrderInfo;
